import React, { useState } from 'react'
import { Link, graphql, navigate } from 'gatsby'
import styled from 'styled-components'
import { Router, Location } from "@reach/router"
import { useSearchParam, useMount } from 'react-use';

import { Layout, Footer, PrivateRoute } from '../components'
import { container, padding, bgImage, hoverState } from '../styles/global'
import { fetchData } from '../services/api';

import Home from './index'
import PressCitations from './press-citations'
import ProjectSingle from '../templates/project-single'
import DefaultPage from '../templates/default-page'

const Preview = () => {
	const [data, setData] = useState(null)
	const [postType, setPostType] = useState(null)
	const postID = useSearchParam('id');

	useMount(() => {
		fetchData(`/more/preview/${postID}`, true, true)
			.then(response => {				
				let data = response;
				// console.log(data.post_type)

				setData(data)
				setPostType(data.post_type)
			})
	})

	const resolveTemplate = () => {
		if (!postType || !data) return;

		const templates = {
			'home': Home,
			'press': PressCitations,
			'projects': ProjectSingle,
			'default': DefaultPage,
		}
		
		const Component = templates[postType];

		return (
			<Component
				pageContext={{
					data: {
						...data,
						...data.acf
					}
				}}
			/>
		)
	}
	
	return (
		<>
			{resolveTemplate()}
		</>
	)
}

const PreviewRouter = () => {
	return (
		<Router>
			<Preview 
				path={`/preview`} 
			/>
		</Router>
	)
}

export default PreviewRouter

